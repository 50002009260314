import React, { useEffect, useState } from 'react';
import { Form, Switch, Input, Space, Select, Button, Radio, InputNumber, Divider, Spin, message } from 'antd';
import { CountryDropdown } from 'react-country-region-selector';
import APIUtils from '../../api/APIUtils'
import WebLinks from '../../api/WebLinks'
import { useHistory, Link } from 'react-router-dom';

const { TextArea } = Input;

const AddBuyer = (props) => {
	const history = useHistory();
	const [loader, setLoader] = useState(false);
	const [countryList, setCountryList] = useState([]);
	const [buyerId, setBuyerId] = useState(null);
	const [buyers, setBuyers] = useState([]);
	const [budgetOptions, setBudgetOptions] = useState([]);
	const [form] = Form.useForm();
	const onCancel = () => {
		form.resetFields();
		history.push('/buyer');
	};
	useEffect(() => {
		countryFetch();
		let budgetOptions = [];
		for (let i = 80; i <= 300; i += 10) {
			budgetOptions.push(i);
		}
		setBudgetOptions(budgetOptions);
	}, []);
	useEffect(() => {
		fetchBuyers()
		if (props.location?.state?.buyerDetailId) {
			setBuyerId(props.location.state?.buyerDetailId)
			fetchBuyerDetail(props.location.state?.buyerDetailId)
		}
		else {
			const result = {
				cpa: 0,
				cpr: 0,
				cpc: 0,
				lowerLimit: 0,
				countryCode: "GB",
				qualityFilterType: "None",
				defaultPriorityLevel: 1,
				cpaCurrency: "GBP",
				cprCurrency: "GBP",
				cpcCurrency: "GBP",
				inheritFiltersBuyerId: 0,
				inheritRulesBuyerId: 0,
				socialBudgetPerAd: 80

			};
			form.setFieldsValue(result);
		}

	}, [props]);

	const fetchBuyers = async () => {
		setLoader(true);
		const response = await APIUtils.apiCall('GET', WebLinks.GetBuyersList);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setBuyers(response.resultObj.data);
			setLoader(false);
		} else {
			setBuyers([]);
			setLoader(false);
		}
	}

	const fetchBuyerDetail = async (id) => {
		setLoader(true)
		const response = await APIUtils.apiCall('GET', WebLinks.GetBuyerDetail + id);
		if (response.resultObj?.statusCode === "2000") {
			let result = response.resultObj.data;
			const trueProperties = ["apiPost", "botPost", "clickPost", "emailPost"];
			const postType = trueProperties.find(property => result[property]);
			result.postType = postType;
			result.gapLocationSkip = !result.gapLocationSkip;
			form.setFieldsValue(result)
		}
		setLoader(false)
	}

	const countryFetch = async () => {
		const response = await APIUtils.apiCall('GET', WebLinks.CountryList);
		if (response.resultObj?.statusCode === "2000") {

			setCountryList(response.resultObj.data);
		}
	};

	const onFinish = async (values) => {
		const { postType, ...rest } = values;

		values = {
			...rest,
			botPost: postType === "botPost",
			apiPost: postType === "apiPost",
			clickPost: postType === "clickPost",
			emailPost: postType === "emailPost",
		};
		//let { password, ...newValues } = values
		setLoader(true)
		let type = 'POST'
		let url = WebLinks.Buyers
		if (buyerId) {
			type = 'PUT'
			values.id = buyerId
			url = WebLinks.UpdateBuyer
		}
		values.gapLocationSkip = !values.gapLocationSkip
		const response = await APIUtils.apiCall(type, url, JSON.stringify(values));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setLoader(false)
			onCancel()
			message.success(response.resultObj.message)
		} else {
			setLoader(false)
			message.error(response.resultObj.message)
		}
	};

	return (
		<Spin spinning={loader}>
			{buyerId &&
				<>
					<div className='flex justify-between items-center'>
						<div className='uppercase font-bold text-xl'>Edit Buyer</div>
						<Link to={{
							pathname: '/edit-employer',
							state: { buyerId: buyerId }
						}}>
							<Button className='uppercase' htmlType="button">
								Edit Employer
							</Button>
						</Link>
					</div>
					<Divider className='my-3' />
				</>}
			<Form
				form={form}
				name="form_in_modal"
				layout="vertical"
				onFinish={onFinish}
			>
				<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
					<Form.Item
						name="name"
						label="Buyer Name"
						rules={[
							{
								required: true,
								message: 'Required field',
							},
						]}
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="reportName"
						label="Report Name"
						rules={[
							{
								required: true,
								message: 'Required field',
							},
						]}
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="email"
						label="Email"
						className="mb-0"
						rules={[
							{
								required: true,
								type: 'email',
							},
						]}
					>
						<Input />
					</Form.Item>
					{buyerId &&
						<Form.Item
							name="password"
							label="Password"
							className="mb-0"
							rules={[
								{
									required: true,
									message: 'Required field',
								},
							]}
						>
							<Input />
						</Form.Item>
					}
					<Form.Item
						name="lowerLimit"
						label="Lower Limit"
						rules={[
							{
								required: true,
								message: 'Required field',
							},
						]}
						className="mb-0"
					>
						<InputNumber className='w-full' />
					</Form.Item>
					<Form.Item
						name="countryCode"
						label="Country"
						rules={[
							{
								required: true,
								message: 'Required field',
							},
						]}
						className="mb-0"
					>
						<Select
							showSearch
							placeholder="Select Country"
							className='w-full'
						>
							{countryList.map((country) => (
								<Select.Option key={country.countryCode} value={country.countryCode}>
									{country.country}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name="indeedSourceName"
						label="Indeed Source Name"
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="loginUrl"
						label="Login URL"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="resetPasswordUrl"
						label="Reset Password URL"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="registrationUrl"
						label="Registration URL"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="termsUrl"
						label="Terms Page URL"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="privacyUrl"
						label="Privacy URL"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="xmlFeedUrl"
						label="Xml Feed Url"
						className="mb-0"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="parserLink"
						label="Parser Link"
						className="mb-0"
					// rules={[
					// 	{
					// 		type: 'url',
					// 		warningOnly: true,
					// 	},
					// 	{
					// 		type: 'string',
					// 		min: 6,
					// 	},
					// ]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="organisationName"
						label="Organisation Name"
						className="mb-0"
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="logoURL"
						label="Logo URL"
						rules={[
							{
								type: 'url',
								warningOnly: true,
							},
							{
								type: 'string',
								min: 6,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						name="emailPostEmail"
						label="Email Post Email:"
					>
						<Input />
					</Form.Item>
					<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
						<Form.Item
							name="cpaCurrency"
							label="CPA Currency"
						>
							<Select
								defaultValue="GBP"
								options={[
									{
										value: 'GBP',
										label: 'GBP',
									},
									{
										value: 'EUR',
										label: 'EUR',
									},
									{
										value: 'USD',
										label: 'USD',
									},
								]}
							/>
						</Form.Item>
						<Form.Item
							name="cpa"
							label="CPA"
							rules={[
								{
									required: true,
									message: 'Required field',
								},
							]}
						>
							<InputNumber />
						</Form.Item>
					</div>
					<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
						<Form.Item
							name="cprCurrency"
							label="CPR Currency"
						>
							<Select
								defaultValue="GBP"
								options={[
									{
										value: 'GBP',
										label: 'GBP',
									},
									{
										value: 'EUR',
										label: 'EUR',
									},
									{
										value: 'USD',
										label: 'USD',
									},
								]}
							/>
						</Form.Item>
						<Form.Item
							name="cpr"
							label="CPR"
							rules={[
								{
									required: true,
									message: 'Required field',
								},
							]}
						>
							<InputNumber />
						</Form.Item>
					</div>
					<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
						<Form.Item
							name="cpcCurrency"
							label="CPC Currency"
						>
							<Select
								defaultValue="GBP"
								options={[
									{
										value: 'GBP',
										label: 'GBP',
									},
									{
										value: 'EUR',
										label: 'EUR',
									},
									{
										value: 'USD',
										label: 'USD',
									},
								]}
							/>
						</Form.Item>
						<Form.Item
							name="cpc"
							label="CPC"
							rules={[
								{
									required: true,
									message: 'Required field',
								},
							]}
						>
							<InputNumber />
						</Form.Item>
					</div>
					<Form.Item
						name="postType"
						label="PostType"
					>
						<Radio.Group>
							<Radio value="botPost">Bot Post</Radio>
							<Radio value="emailPost">Email Post</Radio>
							<Radio value="clickPost">Click Post</Radio>
							<Radio value="apiPost">API Post</Radio>
						</Radio.Group>
					</Form.Item>
					<Form.Item
						name="qualityFilterType"
						label="Quality Filter Type"
					>
						<Select
							defaultValue="None"
							options={[
								{
									value: 'None',
									label: 'None',
								},
								{
									value: 'All',
									label: 'All Jobs',
								},
								{
									value: 'Rules',
									label: 'Filter By Rules',
								},
							]}
						/>
					</Form.Item>
					<Form.Item
						name="defaultPriorityLevel"
						label="Default Priority Level"
					>
						<Select
							defaultValue={1}
							options={[
								{
									value: 0,
									label: '0',
								},
								{
									value: 1,
									label: '1',
								},
								{
									value: 2,
									label: '2',
								},
								{
									value: 3,
									label: '3',
								},
								{
									value: 4,
									label: '4',
								}
							]}
						/>
					</Form.Item>
					<Form.Item
						name="inheritFiltersBuyerId"
						label="Inherit Filters"
					>
						<Select
							defaultValue={0}
							showSearch
							optionFilterProp="children"
						>
							<Select.Option key={0} value={0}>
								{"None"}
							</Select.Option>
							{buyers.map(item => (
								<Select.Option key={item.buyerId} value={item.buyerId}>
									{item.reportName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name="inheritRulesBuyerId"
						label="Inherit Rules"
					>
						<Select
							defaultValue={0}
							showSearch
							optionFilterProp="children"
						>
							<Select.Option key={0} value={0}>
								{"None"}
							</Select.Option>
							{buyers.map(item => (
								<Select.Option key={item.buyerId} value={item.buyerId}>
									{item.reportName}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</div>
				<div className="grid xs:grid-cols-1 lg:grid-cols-4 gap-4">
					<Form.Item
						name="authorised"
						label="Authorised"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch defaultChecked checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="autoUpload"
						label="Auto Upload"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="blockNonLocalIps"
						label="Block Foreign Clicks"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="gapLocationSkip"
						label="Block Foreign Apps"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="forceInternal"
						label="Force Internal"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="premium"
						label="Premium"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Hire Intelligence"
						name="hireIntelligence"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="jobswipeInternalView"
						label="Internal View"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Employer Page"
						name="employerPage"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="selfPost"
						label="Self Post"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="sendDailyByEmail"
						label="Send Daily By Email"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="companyBrandedApply"
						label="Branded Apply"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="allowReports"
						label="Allow Reports"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch defaultChecked checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="showClicksReport"
						label="Show Clicks Report"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch defaultChecked checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="showAppReports"
						label="Show Application Report"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch defaultChecked checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="showRegReports"
						label="Show Registration Report"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch defaultChecked checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="requestJBEPermission"
						label="Request JBE Permission"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="companyFeedback"
						label="Company Feedback"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="jobswipeWebView"
						label="Web View"
						valuePropName="checked"
						initialValue={true}
					>
						<Switch defaultChecked checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="appendAgClickId"
						label="Append Click ID"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Hire Intelligence Admin"
						name="hireIntelligenceAdmin"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Sub Buyer Admin"
						name="subBuyerAdmin"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Diversity Supplier"
						name="diversitySupplierSwitch"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Detailed Budgets"
						name="detailedBudgets"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						label="Recruiter"
						name="recruiter"
						valuePropName="checked"
						initialValue={false}
					>
						<Switch checkedChildren="Yes" unCheckedChildren="No" />
					</Form.Item>
					<Form.Item
						name="socialBudgetPerAd"
						label="Social Budget Per Ad"
					>
						<Select
							defaultValue={80}
						>
							{budgetOptions.map((budget) => (
								<Select.Option key={budget} value={budget}>
									{budget}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</div>
				{buyerId && <>
					<Form.Item
						name="notes"
						label="Notes"
					>
						<TextArea rows={4} maxLength={4000} />
					</Form.Item>
					{/* API post start */}
					<div>
						<div className='font-bold text-lg capitalize'>API Post</div>
						<Divider className="my-3" />
						<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
							<Form.Item
								name="apiPostUrl"
								label="ATS Post URL"
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="APIRegistrationURL"
								label="API Registration URL"
								rules={[
									{
										type: 'url',
										warningOnly: true,
									},
									{
										type: 'string',
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="APIApplicationURL"
								label="API Application URL"
								rules={[
									{
										type: 'url',
										warningOnly: true,
									},
									{
										type: 'string',
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="atsapiKey"
								label="ATS API Key"
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="atsClientId"
								label="ATS Client ID"
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="atsClientSecret"
								label="ATS Secret"
							>
								<Input />
							</Form.Item>
						</div>
					</div>
					{/* Bot Post start */}
					<div>
						<div className='font-bold text-lg capitalize'>Bot Post</div>
						<Divider className="my-3" />
						<div className="grid xs:grid-cols-1 lg:grid-cols-2 gap-4">
							<Form.Item
								name="RecordLimit"
								label="Record Limit"
							>
								<InputNumber className="w-full" />
							</Form.Item>
							<Form.Item
								name="TimeLimit"
								label="Time Limit"
							>
								<InputNumber className="w-full" addonAfter="min." />
							</Form.Item>
						</div>
					</div></>}

				<div className='grid place-content-end'>
					<Space>
						<Form.Item>
							<Button className='uppercase' htmlType="button" onClick={onCancel}>
								Cancel
							</Button>
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Save
							</Button>
						</Form.Item>
					</Space>
				</div>
			</Form>
		</Spin>
	);
};

export default AddBuyer;