import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Input, Space, Button, Popconfirm, Modal, message, Tooltip, Checkbox } from 'antd';
import { EditOutlined, DeleteOutlined, ClusterOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import WebLinks from '../../api/WebLinks';
import APIUtils from '../../api/APIUtils';
import constants from '../../constants/constants';
import Cookies from 'js-cookie';
import AddBuyer from './AddBuyer'
import ParentBuyerAccounts from './ParentBuyerAccounts'

const { Search } = Input;

const ViewBuyer = ({ activeTabKey }) => {
	const history = useHistory();
	const buyerId = Cookies.get(constants.COOKIE_LOGGED_IN_USER_ID)
	const [loader, setLoader] = useState(false);
	const [selectedBuyer, setSelectedBuyer] = useState(null);
	const [selectedBuyerSupplier, setSelectedBuyerSupplier] = useState(null);
	const [isManagePermissionsModalOpen, setIsManagePermissionsModalOpen] = useState(false);
	const [manageParentAccountsModal, setManageParentAccountsModal] = useState(false);
	const [showSuppliersModal, setShowSuppliersModal] = useState(false);
	const [buyers, setBuyers] = useState([]);
	const [buyersList, setBuyersList] = useState([]);
	const [filteredBuyersList, setFilteredBuyersList] = useState([]);
	const [buyersListLoader, setBuyersListLoader] = useState(false);
	const [allowedBuyers, setAllowedBuyers] = useState([]);
	const [allowedSuppliers, setAllowedSuppliers] = useState([]);
	const [supplierIds, setSupplierIds] = useState([])
	const [buyerDetailId, setBuyerId] = useState(null);
	const [total, setTotal] = useState();
	const [buyerListTotal, setBuyerListTotal] = useState();
	const [filteredAllowedSuppliers, setFilteredAllowedSuppliers] = useState([]);
	const [tableConfig, setTableConfig] = useState({
		pageNo: 1,
		limit: 50,
		sortBy: 'id',
		sortDesc: true,
		search: Cookies.get(constants.COOKIE_BUYER_SEARCH) || ''
	});

	useEffect(() => {
		if (activeTabKey === '1') {
			fetchBuyers()
		}
	}, [activeTabKey]);

	useEffect(() => {
		fetchBuyers()
	}, [tableConfig]);

	const fetchBuyers = async () => {
		setLoader(true);
		const queryParams = new URLSearchParams(tableConfig).toString();
		const url = `${WebLinks.Buyers}?buyerId=${buyerId}&${queryParams}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			const dataWithKey = response.resultObj.data.map(item => ({ ...item, key: item.id }));
			setBuyers(dataWithKey);
			setTotal(response.resultObj.getCount)
			setLoader(false);
		} else {
			setBuyers([]);
			setLoader(false);
			message.error("Failed to fetch buyers");
		}
	}

	const showManagePermissionsModal = () => {
		fetchAllowedBuyers()

		setIsManagePermissionsModalOpen(true);
	};

	const showManageParentAccountsModal = () => {
		setManageParentAccountsModal(true)
	}
	const fetchBuyersList = async (allowedBuyers) => {
		setBuyersListLoader(true);
		const url = `${WebLinks.GetBuyersList}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			const buyerIdSet = new Set(allowedBuyers.map((item) => item.subBuyerId));
			//const dataWithKey = response.resultObj.data.map(item => ({ ...item, key: item.buyerId }));
			const dataWithKey = response.resultObj.data.map(item => ({ ...item, key: item.buyerId, subBuyer: buyerIdSet.has(item.buyerId) }));
			dataWithKey.sort((a, b) => {
				if (a.subBuyer && !b.subBuyer) return -1;
				if (!a.subBuyer && b.subBuyer) return 1;
				return 0;
			});
			setBuyersList(dataWithKey);
			setBuyerListTotal(response.resultObj.getCount)
			setBuyersListLoader(false);
		} else {
			setBuyersList([]);
			setBuyersListLoader(false);
			message.error("Failed to fetch buyers");
		}
	};
	const fetchAllowedBuyers = async () => {
		setLoader(true);
		const url = `${WebLinks.GetAllowedBuyers}${selectedBuyer}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			const subBuyerIds = response.resultObj.data.map(item => item.subBuyerId);
			setAllowedBuyers(subBuyerIds);
			fetchBuyersList(response.resultObj.data)
			setLoader(false);
		} else {
			setAllowedBuyers([]);
			setLoader(false);
			message.error("Failed to fetch buyers");
		}
	};
	const handleManagePermissionsOk = () => {
		saveSubBuyers()
		setIsManagePermissionsModalOpen(false);
	};

	const saveSubBuyers = async () => {
		setLoader(true);
		//const buyerIdsParam = allowedBuyers.join(',');
		let payload;
		payload={
			buyerId:selectedBuyer[0],
			subBuyerId:allowedBuyers
		}
		const url = `${WebLinks.SaveSubBuyers}`;
		const response = await APIUtils.apiCall('POST', url,JSON.stringify(payload));
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			message.success("New Allowed Buyers Updated");
			setLoader(false);
		} else {
			setLoader(false);
			message.error("Failed to fetch buyers");
		}
	}
	const handleManagePermissionsCancel = () => {
		setIsManagePermissionsModalOpen(false);
	};

	const [isEditModalOpen, setIsEditModalOpen] = useState(false);

	const showEditModal = (record) => {
		setBuyerId(record.id)
		history.push({
			pathname: '/edit-buyer',
			state: { buyerDetailId: record.id }
		});
		//setIsEditModalOpen(true);
	};
	const handleEditCancel = () => {
		setIsEditModalOpen(false);
	};

	const columns = [
		{
			title: 'ID',
			dataIndex: 'id',
			width: '7%',
			sorter: true
		},
		{
			title: 'Name',
			dataIndex: 'buyerName',
			width: '12%',
			sorter: true,
			render: (_, record) => (
				<div className='text-sky-600 cursor-pointer' onClick={() => showEditModal(record)}>{record.buyerName}</div>
			),
		},
		// {
		// 	title: 'Email',
		// 	dataIndex: 'email',
		// 	sorter: true
		// },
		{
			title: 'Authorise',
			dataIndex: 'authorised',
			align: 'center',
			sorter: true,
			render: (_, data) => <>
				{data.authorised ? <CheckOutlined className='text-xl text-lime-500' /> : <CloseOutlined className='text-xl text-gray-400' />}
			</>,
		},
		{
			title: 'Allowed Suppliers',
			dataIndex: 'allowedSuppliers',
			width: '15%',
			sorter: true,
			render: (_, record) => {
				const suppliersArray = record.allowedSuppliers ? record.allowedSuppliers.split(', ') : [];

				return (
					<div className='text-sky-600 cursor-pointer' onClick={() => handleAction(record.id)}>
						{suppliersArray.length > 0 ? (
							suppliersArray.map((supplier) => (
								<div key={supplier}>{supplier}</div>
							))
						) : (
							<div>None</div>
						)}
					</div>
				);
			},
		},
		{
			title: 'Parser Link',
			dataIndex: 'parserLink',
			width: '10%',
			sorter: true,
		},
		// {
		// 	title: 'CPA',
		// 	dataIndex: 'cpa',
		// },
		{
			title: 'Job Count',
			dataIndex: 'jobCount',
			sorter: true,
			width: '7%',
		},
		{
			title: 'Premium',
			dataIndex: 'premium',
			key: 'premium',
			align: 'center',
			sorter: true,
			render: (_, data) => <>
				{data.premium ? <CheckOutlined className='text-xl text-lime-500' /> : <CloseOutlined className='text-xl text-gray-400' />}
			</>,
		},
		{
			title: 'Quality Review',
			dataIndex: 'manualQualityReview',
			key: 'manualQualityReview',
			align: 'center',
			sorter: true,
			render: (_, data) => <>
				{data.manualQualityReview ? <CheckOutlined className='text-xl text-lime-500' /> : <CloseOutlined className='text-xl text-gray-400' />}
			</>,
		},
		{
			title: 'Force Internal',
			dataIndex: 'forceInternal',
			key: 'forceInternal',
			align: 'center',
			sorter: true,
			render: (_, data) => <>
				{data.forceInternal ? <CheckOutlined className='text-xl text-lime-500' /> : <CloseOutlined className='text-xl text-gray-400' />}
			</>,
		},
		{
			title: 'Post Type',
			dataIndex: 'postType',
			key: 'postType',
			sorter: true,
		},
		{
			title: 'Actions',
			key: 'action',
			align: 'center',
			fixed: 'right',
			width: '8%',
			render: (_, record) => (
				<Space>
					<Tooltip title="Edit">
						<Button size='small' type="link" icon={<EditOutlined />} onClick={() => showEditModal(record)} />
					</Tooltip>
					<Popconfirm title="Sure to delete?" onConfirm={() => deleteBuyer(record)}>
						<Tooltip title="Delete" placement="bottom">
							<Button size='small' type="link" danger icon={<DeleteOutlined />} />
						</Tooltip>
					</Popconfirm>
				</Space>
			),
		}
	];

	// Select Buyers Table
	const SelectBuyersColumns = [
		{
			title: '',
			key: 'buyerId',
			width: '5%',
			render: (_, record) => (
				<Space>
					<Checkbox checked={record.subBuyer} onChange={(e) => changeSubBuyer(e, record)} />
				</Space>
			),
		},
		{
			title: 'ID',
			dataIndex: 'buyerId',
			rowScope: 'row',
			width: '10%',
			sorter: (a, b) => a.buyerId - b.buyerId,
		},
		{
			title: 'Name',
			dataIndex: 'name',
			sorter: (a, b) => a.name - b.name,
		},
		{
			title: 'Parser Link',
			dataIndex: 'parserLink',
		},
	];

	const suppliersColumns = [
		{
			title: '',
			key: 'supplierId',
			width: '5%',
			render: (_, record) => (
				<Space>
					<Checkbox checked={record.allowedSupplier} onChange={(e) => changeAllowedSupplier(e, record)} />
				</Space>
			),
		},
		{
			title: 'ID',
			dataIndex: 'supplierId',
			//rowScope: 'row',
			width: '10%'
		},
		{
			title: 'Name',
			dataIndex: 'name',
			sorter: (a, b) => a.name - b.name,
		},
		{
			title: 'Domain',
			dataIndex: 'domain',
		},
	];

	const changeSubBuyer = (e, record) => {
		const updatedAllowedBuyers = [...allowedBuyers];
		if (e.target.checked) {
			if (!updatedAllowedBuyers.includes(record.buyerId)) {
				updatedAllowedBuyers.push(record.buyerId);
			}
		} else {
			const index = updatedAllowedBuyers.indexOf(record.buyerId);
			if (index !== -1) {
				updatedAllowedBuyers.splice(index, 1);
			}
		}
		setAllowedBuyers(updatedAllowedBuyers);
		if (filteredBuyersList.length > 0) {
			const updatedfilteredBuyersList = filteredBuyersList.map(item => {
				if (item.buyerId === record.buyerId) {
					return { ...item, subBuyer: e.target.checked };
				}
				return item;
			});
			setFilteredBuyersList(updatedfilteredBuyersList)
		}
		const updatedData = buyersList.map(item => {
			if (item.buyerId === record.buyerId) {
				return { ...item, subBuyer: e.target.checked };
			}
			return item;
		});
		setBuyersList(updatedData);
	}

	//Start allowed suppliers
	const handleAction = (id) => {
		setSelectedBuyerSupplier(id)
		fetchAllowedSuppliers(id)
		setShowSuppliersModal(true)
	}

	const fetchAllowedSuppliers = async (id) => {
		setLoader(true);
		const url = `${WebLinks.GetAllowedSuppliers}${id}`;
		const response = await APIUtils.apiCall('GET', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			const allowedSupplierIds = response.resultObj.data
				.filter(item => item.isAllowed)
				.map(item => item.supplierId);
			setSupplierIds(allowedSupplierIds)
			const allowedSupplier = response.resultObj.data.map(supplier => {
				return {
					...supplier,
					allowedSupplier: supplier.isAllowed,
				};
			});

			// Sort the allowedSupplier array to bring allowed suppliers to the top
			allowedSupplier.sort((a, b) => b.allowedSupplier - a.allowedSupplier);
			
			//const dataWithKey = response.resultObj.data.map(item => ({ ...item, key: item.supplierId }));
			setAllowedSuppliers(allowedSupplier);
			setLoader(false);
		} else {
			setAllowedSuppliers([]);
			setLoader(false);
			message.error("Failed to fetch Suppliers");
		}
	}

	const changeAllowedSupplier = async (e, record) => {
		const updatedAllowedSupplier = [...supplierIds];
		if (e.target.checked) {
			if (!updatedAllowedSupplier.includes(record.supplierId)) {
				updatedAllowedSupplier.push(record.supplierId);
			}
		} else {
			const index = updatedAllowedSupplier.indexOf(record.supplierId);
			if (index !== -1) {
				updatedAllowedSupplier.splice(index, 1);
			}
		}
		setSupplierIds(updatedAllowedSupplier);
		if (filteredAllowedSuppliers.length > 0) {
			const updatedfilteredSuppliersList = filteredAllowedSuppliers.map(item => {
				if (item.supplierId === record.supplierId) {
					return { ...item, allowedSupplier: e.target.checked };
				}
				return item;
			});
			setFilteredAllowedSuppliers(updatedfilteredSuppliersList)
		}
		const updatedData = allowedSuppliers.map(item => {
			if (item.supplierId === record.supplierId) {
				return { ...item, allowedSupplier: e.target.checked };
			}
			return item;
		});
		setAllowedSuppliers(updatedData);
	}

	const handleSaveAllowedSuppliers = async () => {
		setLoader(true);
		const supplierIdsParam = supplierIds.join(',');
		const url = `${WebLinks.SaveAllowedSuppliers}${selectedBuyerSupplier}&supplierIds=${encodeURIComponent(supplierIdsParam)}`;
		const response = await APIUtils.apiCall('POST', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setLoader(false);
			message.success(response.resultObj.message)
			fetchBuyers()
			setShowSuppliersModal(false)
		} else {
			setLoader(false);
			message.error("Failed to save Allowed Suppliers");
		}
	}
	//End allowed suppliers

	const onSearch = async (value) => {
		setTableConfig((prevConfig) => ({
			...prevConfig,
			search: value
		}));
		Cookies.set(constants.COOKIE_BUYER_SEARCH, value, { path: '/', expires: constants.COOKIE_EXPIRY_DAYS });
	}

	const onChangeTable = (pagination, filters, sorter) => {
		const { current, pageSize } = pagination;

		// Update the tableConfig state with the new pagination values
		setTableConfig((prevConfig) => ({
			...prevConfig,
			pageNo: current, // Change current to pageNo
			limit: pageSize, // Change pageSize to limit
		}));

		// If there's sorting, update the tableConfig state with the new sorting values
		if (sorter && sorter.field) {
			setTableConfig((prevConfig) => ({
				...prevConfig,
				sortBy: sorter.field,
				sortDesc: sorter.order === 'descend',
			}));
		}
	};

	const deleteBuyer = async (record) => {
		setLoader(true);
		const url = `${WebLinks.DeleteBuyer}${record.id}`;
		const response = await APIUtils.apiCall('PUT', url);
		if (!response.isCatch && response.resultObj.statusCode === "2000") {
			setLoader(false);
			message.success("Buyer deleted successfully");
			fetchBuyers()
		} else {
			setLoader(false);
			message.error("Could not delete buyer");
		}
	}

	const onSearchSubAccounts = (searchTerm) => {
		if (searchTerm) {
			const searchTermLower = searchTerm.toLowerCase();

			const results = buyersList.filter((item) => {
				return (
					item.buyerId.toString().includes(searchTermLower) ||
					item.name.toLowerCase().includes(searchTermLower) ||
					item.parserLink.toLowerCase().includes(searchTermLower)
				);
			});
			setFilteredBuyersList(results)
		}
		else {
			setFilteredBuyersList([])
		}
	}

	const onSearchAllowedSuppliers = (searchTerm) => {
		if (searchTerm) {
			const searchTermLower = searchTerm.toLowerCase();

			const results = allowedSuppliers.filter((item) => {
				return (
					item.supplierId.toString().includes(searchTermLower) ||
					item.name.toLowerCase().includes(searchTermLower) ||
					item.domain.toLowerCase().includes(searchTermLower)
				);
			});
			setFilteredAllowedSuppliers(results)
		}
		else {
			setFilteredAllowedSuppliers([])
		}
	}

	// rowSelection object indicates the need for row selection
	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedBuyer(selectedRowKeys)
		},
		// getCheckboxProps: (record) => ({
		// 	disabled: record.name === 'Disabled User',
		// 	// Column configuration not to be checked
		// 	name: record.name,
		// }),
	};
	const buyerRowSelection = {
		selectedRowKeys: allowedBuyers,
		onChange: (selectedRowKeys, selectedRows) => {
			setAllowedBuyers(selectedRowKeys)
		}
	};
	const supplierRowSelection = {
		selectedRowKeys: supplierIds,
		onChange: (selectedRowKeys, selectedRows) => {
			setSupplierIds(selectedRowKeys)
		}
	};

	const closeModal=()=>{
		setManageParentAccountsModal(false);
	}
	return (
		<div>
			<div className='flex justify-between items-center mb-4'>
				<Space wrap>
					<Button icon={<ClusterOutlined />} type='primary'
						disabled={selectedBuyer !== null ? false : true}
						onClick={showManagePermissionsModal}>
						Manage Sub Accounts
					</Button>
					<Button icon={<ClusterOutlined />} type='primary'
						disabled={selectedBuyer !== null ? false : true}
						onClick={showManageParentAccountsModal}>
						Manage Parent Accounts
					</Button>
				</Space>
				<Search
					placeholder="Search"
					allowClear
					onSearch={onSearch}
					enterButton
					className='w-60'
					defaultValue={tableConfig.search}
				/>
			</div>
			<div>
				<Table
					loading={loader}
					columns={columns}
					dataSource={buyers}
					pagination={{
						pageSizeOptions: ["25", "50", "100"],
						total: total,
						current: tableConfig.pageNo,
						pageSize: tableConfig.limit,
					}}
					onChange={onChangeTable}
					scroll={{
						x: 1700,
					}}
					rowSelection={{
						type: 'radio',
						...rowSelection,
					}}
				/>
			</div>
			<Modal
				title={
					<div className='flex justify-between items-center'>
						{constants.SelectBuyers}
						<Search
							placeholder="Search"
							allowClear
							onSearch={onSearchSubAccounts}
							enterButton
							className='w-60'
						/>
					</div>}
				closable={false}
				open={isManagePermissionsModalOpen}
				onOk={handleManagePermissionsOk}
				okText={<span className='capitalize'>Save Sub Accounts</span>}
				onCancel={handleManagePermissionsCancel}
				className='top-5'
				width={1000}
			>
				<Table
					loading={buyersListLoader}
					columns={SelectBuyersColumns}
					dataSource={filteredBuyersList.length > 0 ? filteredBuyersList : buyersList}
					scroll={{
						y: 600
					}}
					pagination={false}
				/>
			</Modal>
			{/* Manage Parent Accounts Modal */}
			<Modal
				title={
					<div className='flex justify-between items-center'>
						Select Parent Buyers
					</div>}
				open={manageParentAccountsModal}
				//onCancel={() => setManageParentAccountsModal(false)}
				closable={false}
				className='top-5'
				width={1000}
				footer={
					[
						// <Button key="back" onClick={() => setManageParentAccountsModal(false)}>
						// 	Cancel
						// </Button>,
						// <div key="blank" className='w-[165px] inline-block'></div>
					]
				}
			>
				<ParentBuyerAccounts selectedBuyer={selectedBuyer} closeModal={closeModal}/>
			</Modal>
			{/* Edit Buyer Modal */}
			<Modal
				title={<span>{constants.Edit} Buyer</span>}
				open={isEditModalOpen}
				footer={null}
				onCancel={handleEditCancel}
				className='top-5'
				width={1000}
			>
				<AddBuyer buyerDetailId={buyerDetailId} />
			</Modal>
			<Modal
				title={<span>Allowed Suppliers</span>}
				open={showSuppliersModal}
				onOk={handleSaveAllowedSuppliers}
				okText={<span className='capitalize'>Save</span>}
				onCancel={() => setShowSuppliersModal(false)}
				className='top-5'
				width={1000}
				closable={false}
			>
				<div className='absolute top-2.5 right-6'>
					<Space>
						<Search
							placeholder="Search"
							allowClear
							onSearch={onSearchAllowedSuppliers}
							enterButton
							className='w-60'
						/>
					</Space>
				</div> 
				<Table
					// rowSelection={{
					// 	type: 'checkbox',
					// 	...supplierRowSelection
					// }}
					columns={suppliersColumns}
					//dataSource={allowedSuppliers}
					dataSource={filteredAllowedSuppliers.length > 0 ? filteredAllowedSuppliers : allowedSuppliers}
					scroll={{
						y: 300
					}}
					pagination={false}
				/>
			</Modal>
		</div>
	);
};

export default ViewBuyer;
